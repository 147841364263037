import { useState } from "react";
import "./review.css";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { ReactComponent as LoadingIcon } from "../../icons/loading.svg";

const Review = () => {
  const [active, setActive] = useState(0);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  // const order_id = searchParams.get("order_id");
  const seller_id = params.id;

  const set = (num) => {
    if (active !== num) {
      setActive(num);
    }
  };

  return (
    <div className="wrapper">
      <div className="review">
        <div className="title">Give feedback on your experience</div>
        <div className="rating-box">
          {[...Array(5)].map((_, i) => {
            return (
              <div
                key={i}
                className={
                  "rating-btn " +
                  (active >= i + 1 || selected >= i + 1 ? "active" : "")
                }
                onMouseEnter={() => set(i + 1)}
                onMouseLeave={() => set(0)}
                onClick={() => setSelected((v) => (v === i + 1 ? 0 : i + 1))}
              >
                {i + 1}
              </div>
            );
          })}
        </div>
        <button
          className="btn"
          disabled={selected === 0}
          onClick={() => {
            setLoading(true);
            if (submitted) {
              setLoading(false);
              toast.warning("Review already submitted.", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return;
            }
            try {
              if (seller_id) {
                axios
                  .post(
                    "https://api1-staging.tagonstore.com/tagon_erp/erp_sellerreviews/",
                    {
                      seller_review: seller_id,
                      rate_1: selected === 1 ? 1 : 0,
                      rate_2: selected === 2 ? 1 : 0,
                      rate_3: selected === 3 ? 1 : 0,
                      rate_4: selected === 4 ? 1 : 0,
                      rate_5: selected === 5 ? 1 : 0,
                    }
                  )
                  .then((res) => {
                    toast.success("Review submitted successfull", {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setLoading(false);
                    setSubmitted(true);
                  })
                  .catch((e) => {
                    toast.error("Something went wrong!", {
                      position: "bottom-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setLoading(false);
                  });
              }
            } catch (e) {
              toast.error("Something went wrong!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setLoading(false);
            }
          }}
        >
          {loading ? <LoadingIcon height={50} width={50} /> : null}
          SUBMIT
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Review;
