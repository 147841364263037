import "./footer.css";
import { ReactComponent as Insta } from "../../icons/insta.svg";
import { ReactComponent as FooterLogo } from "../../icons/footer-logo.svg";
import { ReactComponent as Facebook } from "../../icons/facebook.svg";
import { ReactComponent as Linkedin } from "../../icons/linkedin.svg";
import { Link } from "react-router-dom";

export const Footer = ({ noSnap }) => {
  return (
    <div className={"footer " + (noSnap ? "no-snap" : "")}>
      <div className="container footer-container">
        <div>
          <div className="ql-title">QUICK LINKS</div>
          <div className="p-10" />
          <div className="ql-links">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-and-condition">Terms & Conditions</Link>
          </div>
        </div>
        <FooterLogo className="footer-logo" />
        <div>
          <div className="ql-title">Follow Us On</div>
          <div className="p-10" />
          <div
            className="ql-links"
            style={{ flexDirection: "row", gap: "15px", flexWrap: "wrap" }}
          >
            <a
              href="https://www.facebook.com/pentagonbow/"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook width={40} />
            </a>
            <a
              href="https://www.instagram.com/pentagontape/"
              target="_blank"
              rel="noreferrer"
            >
              <Insta width={40} />
            </a>
            <a
              href="https://in.linkedin.com/company/pentagon-tapes"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin width={40} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
