import React, { useEffect, useRef } from "react";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const ref = useRef();
  useEffect(() => {
    ref?.current?.scrollTo(0, 0);
  }, []);

  return (
    <main className="main" ref={ref}>
      <Header />
      <div className="container">
        <Article />
      </div>
      <div className="p-10" />
      <div className="p-10" />
      <Footer noSnap />
    </main>
  );
};

const Article = () => {
  return (
    <article className="prose">
      <h1 id="privacy-notice-privacy-policy">PRIVACY POLICY</h1>
      <p>
        PENTAGON Tapes Private Limited (&quot;<strong>PTPL</strong>&quot; or
        &quot;<strong>we</strong>&quot;) takes the privacy of your information
        seriously. This Privacy Notice describes the Data we collect from you
        through our website, including sub-domains microsites, and mobile
        applications (&quot;<strong>Platforms</strong>&quot;). It also describes
        the purposes for which we collect that personal information, the other
        parties with whom we may share it and the measures we take to protect
        the security of your data. It also tells you about your rights and
        choices with respect to your personal information, and how you can
        contact us about our privacy practices.
      </p>
      <p>
        This Privacy Notice &amp; Privacy Policy (&quot;<strong>Policy</strong>
        &quot;) is published in accordance with Rule 4 of the Information
        Technology (Reasonable Security Practices and Procedures and Sensitive
        Personal Information or Data) Rules, 2011 read with Rule 3(1) of the
        provisions of Information Technology (Intermediary Guidelines and
        Digital Media Ethics Code) Rules, 2021.
      </p>
      <p>
        You are advised to carefully read this Privacy Notice before using or
        availing any of the products and/or services offered on our Platforms.
      </p>
      <h2 id="definitions">DEFINITIONS</h2>
      <p>In this Privacy Notice, the following definitions are used :</p>
      <ul>
        <li>
          <p>
            <strong>Cookies : </strong>a small file placed on your device by our
            Platforms when you either visit or use certain features of our
            Platforms. A cookie generally allows a website to remember your
            actions or preference for a certain period of time.
          </p>
        </li>
        <li>
          <p>
            <strong>Data : </strong>
            includes non-personal information, personal information and
            sensitive personal information about you, which either directly or
            indirectly in combination with other information, could allow you to
            be identified when you visit our Platforms.
          </p>
        </li>
        <li>
          <p>
            <strong>Data Protection Laws : </strong>
            any applicable law for the time being in force relating to the
            processing of Data.
          </p>
        </li>
        <li>
          <p>
            <strong>Partners : </strong>
            select third parties (including Pentagon Group Entities) with whom
            we have contracts for the businesses described in this Privacy
            Notice.
          </p>
        </li>
        <li>
          <p>
            <strong>Service Providers : </strong>
            includes entities to whom we or other Pentagon Group Entities may
            disclose your Data in order to process information for a specific
            purpose pursuant to written contract.
          </p>
        </li>
        <li>
          <p>
            <strong>PTPL : </strong>
            PENTAGON Tapes Private Limited, a company incorporated in India
            whose registered office is at 6th Floor, K. Raheja Prime, Marol
            Indl. Socy., Sag Baug Road, Marol Naka, Andheri East, Mumbai
            Maharashtra 400059.
          </p>
        </li>
        <li>
          <p>
            <strong>Pentagon Group Entity : </strong>
            Pentagon Tapes Private Limited, and its subsidiaries, affiliates,
            associate companies and joint venture companies with whom we have a
            contractual arrangement to, <em>inter alia</em>, share data for the
            purposes described in this Privacy Notice.
          </p>
        </li>
        <li>
          <p>
            <strong>User or you : </strong>
            the natural person who accesses our Platforms.
          </p>
        </li>
      </ul>
      <h2 id="what-data-do-we-collect-about-you">
        WHAT DATA DO WE COLLECT ABOUT YOU
      </h2>
      <p>
        PTPL collects Data for various purposes set out in this Privacy Notice.
      </p>
      <p>
        This Data may include, without limitation, the following categories :
      </p>
      <ul>
        <li>
          <p>
            <strong>Contact information :</strong> first and last name, email
            address, postal address, country, employer, phone number and other
            similar contact data.
          </p>
        </li>
        <li>
          <p>
            <strong>Financial information :</strong> payment instrument
            information, transactions, transaction history, preferences, method,
            mode and manner of payment, spending pattern or trends, and other
            similar data.
          </p>
        </li>
        <li>
          <p>
            <strong>Technical information :</strong> website, device and mobile
            app usage, Internet Protocol (IP) address and similar information
            collected via automated means, such as cookies, pixels and similar
            technologies.
          </p>
        </li>
        <li>
          <p>
            <strong>Transaction information :</strong> the date of the
            transaction, total amount, transaction history and preferences and
            related details.
          </p>
        </li>
        <li>
          <p>
            <strong>Product and service information :</strong> Your account
            membership number, registration and payment information, and
            program-specific information, when you request products and/or
            services directly from us, or participate in marketing programs.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal information :</strong> Age, sex, date of birth,
            marital status, nationality, details of government identification
            documents provided, thumb impression, occupation, or any other
            personal information provided in product reviews or responses to
            surveys or questionnaires.
          </p>
        </li>
        <li>
          <p>
            Your reviews, feedback and opinions about our products, programmes
            and services.
          </p>
        </li>
        <li>
          <p>
            <strong>Loyalty programme information :</strong> your loyalty
            membership information, account details, profile or password details
            and any frequent flyer or travel partner programme affiliation.
          </p>
        </li>
      </ul>
      <h2 id="how-we-collect-data">HOW WE COLLECT DATA</h2>
      <p>We collect Data in the following ways :</p>
      <ul>
        <li>
          <p>
            <strong>Information You Give Us :</strong> We receive and store any
            information you enter on our Platforms or give us in any other way
            (e.g., at outlets, stores, hotels, kiosks). Please see the section
            titled &quot;Data Shared by You&quot; for more information.
          </p>
        </li>
        <li>
          <p>
            <strong>Automatic Information We Collect :</strong> We use
            &quot;cookies&quot;, pixels and similar technologies to receive and
            store certain types of information whenever you interact with us.
            Please see the section below, titled &quot;Data that is Collected
            Automatically&quot; for more information.
          </p>
        </li>
        <li>
          <p>
            <strong>E-mail Communications :</strong> To help us make e-mails
            more relevant and interesting, we often receive a confirmation (if
            your device supports such capabilities) when you open e-mail from us
            or click on a link in the e-mail. You can choose not to receive
            marketing emails from us by clicking on the unsubscribe link in any
            marketing email.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Automatic Information We Collect from Other Websites :
            </strong>{" "}
            We receive and store certain types of information when you interact
            with third-party websites that use our technology or with whom we
            have a specific agreement. Because we process this information on
            behalf of the applicable website operators, collection, processing,
            and use of such information is subject to the applicable website
            operators&#39; specific privacy policies and is not covered by our
            Privacy Notice.
          </p>
        </li>
        <li>
          <p>
            <strong>Information from Other Sources : </strong> We may obtain
            information from other sources. An example of this is when you
            authorize a third-party website (such as the website of another
            Pentagon Group Entity), to interact directly with our Platforms to
            provide or receive Data about you. In that case, we might receive
            such Data used by that third-party website to identify your account
            with that website.
          </p>
        </li>
      </ul>
      <p>
        You can make choices about our collection and use of your Data. For
        example, you may want to access, edit or remove your Data on our
        Platforms. When you are asked to provide Data, you may decline.
      </p>
      <h2 id="data-shared-by-you">DATA SHARED BY YOU</h2>
      <p>
        PTPL may collect your Data in several ways from your use of our
        Platforms. For instance :
      </p>
      <ul>
        <li>
          When you register with us to purchase products and / or services,
          directly from us or from sellers listed on our Platforms.
        </li>
        <li>
          When you conduct a transaction with us or attempt a transaction at on
          our Platforms.
        </li>
        <li>when you complete surveys conducted by or for us.</li>
        <li>
          When you elect to receive any communications (including promotional
          offers) from us.
        </li>
        <li>from the information gathered by your visit to our Platforms.</li>
      </ul>
      <p>
        If you provide Data on behalf of any corporation, company, institution,
        association or other entity, you represent that you are providing the
        same under authority from such corporation, company, institution,
        association or other entity.
      </p>
      <h2 id="data-that-is-collected-automatically">
        DATA THAT IS COLLECTED AUTOMATICALLY
      </h2>
      <ul>
        <li>
          <p>
            We automatically collect some information when you visit our
            Platforms. This information helps us to make improvements to our
            content and navigation. The information collected automatically
            includes your IP address.
          </p>
        </li>
        <li>
          <p>
            Our web servers or affiliates who provide analytics and performance
            enhancement services collect IP addresses, operating system details,
            browsing details, device details and language settings. This
            information is aggregated to measure the number of visits, average
            time spent on the site, pages viewed and similar information. PTPL
            uses this information to measure the site usage, improve content and
            to ensure safety and security, as well enhance performance of our
            Platforms.
          </p>
        </li>
        <li>
          <p>
            We may collect your Data automatically via Cookies, pixels and
            similar technologies in line with settings on your browser. For more
            information about Cookies, please see the section below, titled
            &quot;<strong>Cookies</strong>&quot;.
          </p>
        </li>
      </ul>
      <h2 id="our-use-of-data">OUR USE OF DATA</h2>
      <p>
        Any or all the above Data may be required by us from time to time to
        provide information relating to PTPL and to work on the experience when
        using our Platforms. Specifically, Data may be used by us for the
        following reasons :
      </p>
      <ul>
        <li>
          Carry out our obligations arising from any contract entered into with
          PTPL or between you and sellers on the Platforms.
        </li>
        <li>
          Provide products and/or services and communicate with you about
          products and/or services offered on our Platforms.
        </li>
        <li>
          Enable Pentagon Group Entities and Partners to offer their products
          and/or services and communicate with you about such products and/or
          services.
        </li>
        <li>
          Processing, disclosing, transmitting, and/or sharing the
          data/information with Pentagon Group Entities, and other third parties
          which have business or contractual dealings with us.
        </li>
        <li>
          Provide you with offers (including for financial products and/or
          services), personalized services and recommendations and improve your
          experience on our Platforms.
        </li>
        <li>Operate, evaluate and improve our business and our Platforms.</li>
        <li>
          Generate aggregated data to prepare insights to enable us to
          understand customer behaviour, patterns and trends with a view to
          learning more about your preferences or other characteristics.
        </li>
        <li>
          Provide privileges and benefits to you, marketing and promotional
          campaigns based on your profile.
        </li>
        <li>
          In connection with loyalty programs owned and operated by us or by
          other Pentagon Group Entities.
        </li>
        <li>
          Communicate with you (including to respond to your requests,
          questions, feedback, claims or disputes) and to customize and improve
          our services.
        </li>
        <li>Enforce the terms of use of our Platforms.</li>
        <li>
          Protect against and prevent fraud, illegal activity, harm, financial
          loss and other legal or information security risks.
        </li>
        <li>
          Serve other purposes for which we provide specific notice at the time
          of collection, and as otherwise authorized or required by applicable
          law.
        </li>
      </ul>
      <p>
        We treat these inferences as personal information (or sensitive personal
        information, as the case may be), where required under applicable law.
        Some of the above grounds for processing will overlap and there may be
        several grounds which justify our use of your personal information.
      </p>
      <p>
        Where required under applicable law, we will only use your personal
        information with your consent; as necessary to provide you with products
        and/or services; to comply with a legal obligation; or when there is a
        legitimate interest that necessitates the use.
      </p>
      <h2 id="minors">MINORS</h2>
      <p>
        Our Platforms do not offer products or services for use by minors. If
        you are under 18, you may use our Platforms only with the involvement of
        a parent or guardian.
      </p>
      <h2 id="sharing-of-data">SHARING OF DATA</h2>
      <p>We may share your Data with/ for:</p>
      <ul>
        <li>
          <p>
            <strong>Partners:</strong> We may make available to you services,
            products, or applications provided by Partners for use on or through
            our Platforms. If you choose to use such service, customer
            information related to those transactions may be shared with such
            Partner. Such Partners will be required to respect the security of
            your Data and to treat it in accordance with this privacy policy and
            applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Pentagon Group Entities</strong> : We may make available to
            you products, services and /or applications of Pentagon Group
            Entities, to assist them to reach out to you in relation to their
            programs or campaigns and to process your queries and requests.
            Accordingly, we may share your Data with Pentagon Group Entities. We
            may also share your Data with the Pentagon Group Entities as is
            relevant for the purposes set out in Clause 7 above, and to
            facilitate the operation of our business.
          </p>
        </li>
        <li>
          <p>
            <strong>Pentagon Consumer Platform</strong> : Your Data may be
            shared with Pentagon Group Entities and other participating entities
            on the Pentagon Consumer Platform for purposes of enrolment,
            offering you products, services and_ _benefits. Accordingly, we may
            share your Data with other Pentagon Group Entities, Partners and
            Service Providers.
          </p>
        </li>
        <li>
          <p>
            <strong>Service Providers:</strong> We or other Pentagon Group
            Entities may share your Data with Service Providers. Examples
            include storing and analyzing Data, protecting and securing our
            systems, providing search results and links, providing customer
            service, credit analysis, processing your information for profiling,
            user analysis and payment processing. These Service Providers will
            be required to only process Data in accordance with express
            instructions and as necessary to perform services for purposes set
            forth in this Privacy Notice. The Service Providers will also be
            required to safeguard the security and confidentiality of the Data
            they process by implementing appropriate technical and
            organizational security measures and confidentiality obligations
            binding employees accessing Data.
          </p>
        </li>
        <li>
          <p>
            <strong>When PTPL acts as a Service Provider: </strong> We may
            process and share your Data with Pentagon Group Entities and
            Partners when we act as a service provider to such Pentagon Group
            Entities and Partners.
          </p>
        </li>
        <li>
          <p>
            <strong>Protecting PTPL:</strong> We may release Data when we
            believe release is appropriate to comply with applicable law or
            legal process, enforce or apply the <strong>Terms of Use </strong>{" "}
            of our Platform and other agreements, protect PTPL against harm or
            financial loss, when we believe disclosure is necessary to protect
            individuals&#39; vital interests, or in connection with an
            investigation of suspected or actual fraudulent or illegal activity.
            This may include exchanging information with other companies and
            organizations for fraud protection, risk management and dispute
            resolution. This does not include selling or otherwise disclosing
            personally identifiable information from users for commercial
            purposes in violation of this Privacy Notice.
          </p>
        </li>
        <li>
          <p>
            <strong>Business Transfers:</strong> As we continue to develop our
            business, we might sell or buy subsidiaries or business units. Your
            Data (including in relation to loyalty programs) may be transferred
            as part of such transaction.
          </p>
        </li>
        <li>
          <p>
            <strong>Third Parties</strong> : We may also share your Data with
            other third parties where:
          </p>
          <ul>
            <li>You request or authorize us to do so;</li>
            <li>
              We need to comply with applicable law or respond to valid legal
              process; or
            </li>
            <li>
              We need to operate and maintain the security of our Platforms,
              including to prevent or stop an attack on our computer systems or
              networks.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        We require these third parties by contract to only process sensitive
        personal data in accordance with our instructions and as necessary to
        perform services on our behalf or in compliance with applicable law. We
        also require them to safeguard the security and confidentiality of the
        sensitive personal data they process on our behalf by implementing
        appropriate confidentiality, technical and organizational security
        measures.
      </p>
      <p>
        Please note that Pentagon Group Entities and Partners may have privacy
        practices that differ from those of PTPL. The use of your Data will be
        governed by their privacy statements when you provide Data on their
        websites.
      </p>
      <h2 id="keeping-data-secure">KEEPING DATA SECURE</h2>
      <p>
        We will use technical and organisational measures to safeguard your Data
        and we store your Data on secure servers. Technical and organisational
        measures include measures to deal with any suspected data breach. If you
        suspect any misuse or loss or unauthorised access to your Data, please
        let us know immediately by contacting us by e-mail.
      </p>
      <h2 id="retention-of-data">RETENTION OF DATA</h2>
      <p>
        PTPL retains Data for as long as necessary for the use of our Platforms
        or to provide access to and use of our Platforms, or for other essential
        purposes such as complying with our legal obligations, resolving
        disputes, enforcing our agreements and as long as processing and
        retaining your Data is necessary for our legitimate interests. Because
        these needs can vary for different data types and purposes, actual
        retention periods can vary significantly.
      </p>
      <p>
        Even if we delete your Data, including on account of exercise of your
        right under Clause 11 below, it may persist on backup or archival media
        for audit, legal, tax or regulatory purposes.
      </p>
      <h2 id="your-rights-and-choices">YOUR RIGHTS AND CHOICES</h2>
      <p>
        When we process Data about you, we do so with your consent and/or as
        necessary to operate our business, meet our contractual and legal
        obligations, protect the security of our systems and our customers, or
        fulfil other legitimate interests of PTPL as described in this Privacy
        Notice.
      </p>
      <p>
        We may transfer Data we collect about you to recipients in India, where
        we are headquartered. India may not have the same Data Protection Laws
        as the country in which you initially provided the information. When we
        transfer your Data to India, we will protect that information as
        described in this Privacy Notice, as disclosed to you at the time of
        data collection or as described in our program-specific privacy notice.
        For processing your Data under this Policy we may transfer your Data to
        Partners outside India. Such transfer shall be in accordance with Data
        Protection Laws of India.
      </p>
      <p>
        Depending on which Data Protection Laws are applicable to you, you may
        have the right or choice to:
      </p>
      <ul>
        <li>
          Opt out of some collection or uses of your Data, including the use of
          cookies, pixels and similar technologies and the use of your Data for
          marketing purposes.
        </li>
        <li>
          Access your Data, rectify it, restrict or object to its processing, or
          request its deletion or anonymization.
        </li>
        <li>Change or edit information submitted to us.</li>
        <li>
          Receive the Data you provided to us to transmit it to another company.
        </li>
        <li>Withdraw any consent provided or alter your preferences.</li>
        <li>
          Where applicable, lodge a complaint with your supervisory authority.
        </li>
      </ul>
      <p>
        You may submit a request as described in the &quot;
        <strong>How to Contact Us</strong>
        &quot; section below. We will not charge you for any request. Where we
        are legally permitted to do so, we may refuse your request. If we refuse
        your request, we will tell you the reasons why. You may write to us for
        the following rights in relation to your Data:
      </p>
      <ul>
        <li>
          <em>Right to Correction</em> - the right to have your Data rectified
          if it is inaccurate or incomplete.
        </li>
        <li>
          <em>Right to request deletion</em>- the right to request that we
          delete or remove your Data from our systems.
        </li>
        <li>
          <em>Right to Withdraw Consent</em> – the right to withdraw your
          consent provided earlier.
        </li>
      </ul>
      <p>
        For information about managing your Data and promotional communications,
        please connect with us by accessing this link{" "}
        <a href="https://tagonstore.com/#contactus">
          https://tagonstore.com/#contactus
        </a>
      </p>
      <p>
        It is important that the Data we hold about you is accurate and current.
        Please keep us informed if your personal information changes during the
        period for which we hold it.
      </p>
      <h2 id="where-we-store-data">WHERE WE STORE DATA</h2>
      <p>
        Data collected under this Privacy Notice is hosted on servers located in
        India.
      </p>
      <h2 id="processing-your-data">PROCESSING YOUR DATA</h2>
      <p>
        We take steps to ensure that the Data we collect under this Privacy is
        processed according to the provisions of this Policy and the
        requirements of applicable law.
      </p>
      <p>
        To ensure that your Data receives an adequate level of protection, we
        have put in place appropriate written contracts with Pentagon Group
        Entities, Partners and Service Providers that we share your Data with.
        This ensures your Data is treated by such parties in a way that is
        consistent with applicable law.
      </p>
      <h2 id="cookies-pixels-and-similar-technologies">
        COOKIES, PIXELS AND SIMILAR TECHNOLOGIES
      </h2>
      <p>
        Our website and mobile application may place and access certain Cookies
        on your device. Cookies are unique identifiers that we transfer to your
        device to enable our systems to recognize your device. We also use
        pixels and similar technologies to analyse traffic on our website and
        mobile application to improve your experience of using them.
      </p>
      <p>
        When you first visit our website and mobile application, you will be
        presented with a pop-up screen informing you of our use of Cookies and
        asking you to provide your consent for such use. The pop-up screen may
        not reappear for all your subsequent visits to our website and mobile
        application, but you may adjust your web browser software if you do not
        wish to receive Cookies or web beacons, but this may prevent you from
        taking advantage of some of the features of our website and mobile
        application. Please refer to your browser instructions or help pages to
        learn more about these functions.
      </p>
      <h2 id="security">SECURITY</h2>
      <p>
        PTPL implements standard measures to protect against unauthorized access
        to and unlawful interception of Data. However, no internet site can
        fully eliminate security risks. PTPL endeavors to take all measures to
        protect the security, integrity and confidentiality of the Data against
        unauthorized breach and hacking. For the purpose of checking possible
        vulnerabilities and attacks, PTPL may conduct periodical internal review
        of data and security measures on the Platforms. However the internet is
        not absolutely a secure environment, and the Platforms cannot ensure or
        warrant a 100% security of the Platforms.
      </p>
      <p>
        If you are provided with any identification code, password or any other
        piece of information as part of the Website&#39;s security procedure,
        you should treat such information as confidential and not disclose it to
        any third party including to other users. You are solely responsible for
        the activities that occur under your account including the
        confidentiality of the your password and PTPL is not responsible for the
        same.
      </p>
      <p>
        Notwithstanding anything contained in this Policy or elsewhere, PTPL
        shall not be held responsible for any loss, damage or misuse of the
        Data, if such loss, damage or misuse is attributable to a Force Majeure
        Event. A <strong>&quot;Force Majeure Event&quot;</strong> means any
        event that is beyond the reasonable control of PTPL and includes,
        without limitation, fire, flood, explosion, acts of God, civil
        commotion, strikes, lock outs or industrial action of any kind, riots,
        insurrection, war, acts of government, power failure, sabotage, computer
        hacking, unauthorised access to computer data and storage device, system
        failure, virus, attacks, bugs, computer crashes, breach of security and
        encryption.
      </p>
      <h2 id="severability">SEVERABILITY</h2>
      <p>
        If any court or competent authority finds that any provision of this
        Policy (or part of any provision) is invalid, illegal or unenforceable,
        that provision or part-provision will, to the extent required, be deemed
        to be deleted, and the validity and enforceability of the other
        provisions of this Policy will not be affected.
      </p>
      <h2 id="changes-to-this-policy">CHANGES TO THIS POLICY</h2>
      <p>
        Our business changes constantly and our Policy will change also. We may
        e-mail periodic reminders of our notices and conditions, unless you have
        instructed us not to, but you should check our Platforms frequently to
        see recent changes. The updated version will be effective as soon as it
        is accessible. Any changes will be immediately posted on our Platforms
        and you are deemed to have accepted the terms of the updated Policy on
        your first use of our Platform or first purchase of the products and/or
        services following the alterations. We encourage you to review this
        Policy frequently to be informed of how we are protecting your
        information.
      </p>
      <h2 id="how-to-contact-us">HOW TO CONTACT US</h2>
      <p>
        To request to review, update, or delete your personal information or to
        otherwise reach us, please submit a request by e-mailing us at
        admin@tagonstore.com You may contact us for information on Service
        Providers, Partners and Pentagon Group Entities with whom we may share
        your Data in compliance with this Privacy Notice and applicable law. We
        will respond to your request within 30 days.
      </p>
      <h2 id="grievance-officer">GRIEVANCE OFFICER</h2>
      <p>Please see below the details of our grievance officer:</p>
      <p>Name: Anuj J (Manager)</p>
      <p>
        Email: <a href="mailto:admin@tagonstore.com">admin@tagonstore.com</a>
      </p>
      <p>Phone number: 91-9987706817</p>
      <p>
        We are operational between 10:00 a.m. to 06:00 p.m., five days a week.
      </p>
      <p>
        Address: 6th Floor, K Raheja Prime, Marol Indl. Socy., Sag Baug Rd.,
        Marol Nakam Andheri (E), Mumbai – 400059
      </p>
    </article>
  );
};
