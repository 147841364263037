import { useState } from "react";
import { ReactComponent as QrCodeIcon } from "../../icons/qrcode.svg";
import "./qrcode.css";
import { AnimatePresence, motion } from "framer-motion";
import qrImage from "../../images/qr-code.png";

export const QrLink =
  "https://drive.google.com/drive/folders/1GN75XkW4Aakagq4A8xgBoYkxrkhB8EXO?usp=sharing";

export const Qrcode = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <motion.div className="qrcode-trigger" onClick={() => setOpen(true)}>
        <QrCodeIcon className="qrcode-icon" />
      </motion.div>
      <AnimatePresence>
        {open && (
          <motion.div
            className="qr-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="qr-modal"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
            >
              <motion.div
                className="qr-modal-close"
                onClick={() => setOpen(false)}
              >
                &#10799;
              </motion.div>
              <div className="qr-title">Scan code to download</div>
              <a href={QrLink} target="_blank" rel="noreferrer">
                <img className="qr-image" src={qrImage} alt="qr" />
              </a>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
