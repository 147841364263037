import "./header.css";
import { ReactComponent as Logo } from "../../images/logo.svg";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import { QrLink, Qrcode } from "../qrcode/qrcode";

export function Header() {
  return (
    <div className="header-wrapper">
      <div className="container">
        <div className="header">
          <Link to="/">
            <Logo className="header-logo" />
          </Link>
          <a href={QrLink} target="_blank" rel="noreferrer" className="nav">
            <FaApple className="nav-icon" />
            <FaGooglePlay className="nav-icon-play" />
          </a>
        </div>
      </div>
      <Qrcode />
    </div>
  );
}
