import React from "react";
import "./App.css";

import { Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/home/home";
import { PrivacyPolicy } from "./pages/privacy/privacy";
import { Terms } from "./pages/terms/terms";
import AfterRegistration from "./pages/email_verification/email_verification";
import Review from "./pages/review/review";
import DeleteMyAccount from "./pages/delete_my_account/delete_my_account";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-and-condition" element={<Terms />} />
        <Route
          exact
          path="email_verification/"
          element={<AfterRegistration />}
        />
        <Route exact path="/review/:id" element={<Review />} />
        <Route exact path="/delete-my-account" element={<DeleteMyAccount />} />
      </Routes>
    </div>
  );
}

export default App;
