import { Header } from "../../components/header/header";
import "./home.css";
import mainImage from "../../images/main_image.png";
import feature1 from "../../images/feature1.png";
import feature2 from "../../images/feature2.png";
import feature3 from "../../images/feature3.png";
import feature4 from "../../images/feature4.png";
import feature5 from "../../images/feature6.png";
import contactUsImage from "../../images/contact_us.jpg";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { FaRegCirclePlay } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";
import { HiOutlineCurrencyRupee } from "react-icons/hi";
import { ReactComponent as LoadingIcon } from "../../icons/loading.svg";
import { Footer } from "../../components/footer/footer";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export function HomePage() {
  const matches = useMediaQuery("screen and (max-width: 500px)");
  const ref = useRef();
  useEffect(() => {
    ref?.current?.scrollTo(0, 0);
  }, []);

  return (
    <main className="main" ref={ref}>
      <div className="main-section">
        <Header />
        <div style={{ padding: "30px" }} />
        <motion.div
          initial={{ y: `-${100}px`, opacity: 0 }}
          whileInView={{ y: "0px", opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, delay: 0.3 }}
          className="main-title"
        >
          Discover the Ultimate <span className="main-shopping">Shopping</span>{" "}
          Experience
        </motion.div>
        <div style={{ padding: "25px" }} />
        <motion.img
          initial={{ y: `-${100}px`, opacity: 0 }}
          whileInView={{ y: "0px", opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, delay: 0.3 }}
          className="main-image"
          src={mainImage}
          alt="main_image"
        />
      </div>
      {features.map((d, idx) => {
        const sectionOne = (
          <div className="feature">
            <d.icon className="feature-icon" />
            <div className="feature-title">{d.title}</div>
            <div className="feature-subtitle">{d.subtitle}</div>
          </div>
        );
        const sectionTwo = (
          <img
            className={"feature-image" + (idx + 1).toString()}
            src={d.image}
            alt="demo"
          />
        );
        if (matches) {
          return (
            <div className="section-full">
              <TwoSideSection sectionOne={sectionTwo} sectionTwo={sectionOne} />
            </div>
          );
        }
        return (
          <>
            <div className="section-full">
              <TwoSideSection
                sectionOne={idx % 2 === 0 ? sectionOne : sectionTwo}
                sectionTwo={idx % 2 === 0 ? sectionTwo : sectionOne}
              />
            </div>
          </>
        );
      })}
      <div className="section-full">
        <div className="main-small-title">Contact Us</div>
        <div className="p-5" />
        <div className="contact-us">
          <img
            className="contact-us-image"
            src={contactUsImage}
            alt="demo_image"
          />
          <ContactForm />
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
    </main>
  );
}

const initialData = {
  visitorName: "",
  visitorEmail: "",
  visitorMessage: "",
};

const ContactForm = () => {
  const [data, setData] = useState(initialData);
  const [saving, setSaving] = useState(false);
  const set = (key, value) => {
    setData((d) => ({
      ...d,
      [key]: value,
    }));
  };

  const url = `${process.env.REACT_APP_API}/web/contact/`;
  return (
    <form
      id="contactus"
      className="contact-form"
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        try {
          axios
            .post(url, data, {
              headers: { "Content-Type": "application/json" },
            })
            .then(() => {
              toast.success(
                "Thank you for contacting, we will get back to you soon."
              );
              setSaving(false);
              setData(initialData);
            })
            .catch((e) => {
              // if (e.response.data) {
              //   setErrors(e.response.data);
              // } else {
              toast.error("Something went wrong.");
              // }
              setSaving(false);
            });
        } catch (e) {
          toast.error("Something went wrong.");
        }
      }}
    >
      <label>
        Name
        <input
          type="text"
          placeholder="Name"
          value={data.visitorName}
          onChange={(e) => set("visitorName", e.target.value)}
          required
          disabled={saving}
        />
      </label>
      <label>
        Email
        <input
          type="email"
          placeholder="Email"
          value={data.visitorEmail}
          onChange={(e) => set("visitorEmail", e.target.value)}
          required
          disabled={saving}
        />
      </label>
      <label>
        Comment
        <textarea
          rows={2}
          placeholder="Comment"
          value={data.visitorMessage}
          onChange={(e) => set("visitorMessage", e.target.value)}
          required
          disabled={saving}
        />
      </label>
      <button className="submit-btn" disabled={saving}>
        {saving ? <LoadingIcon height={50} width={50} /> : null}
        Submit
      </button>
    </form>
  );
};

const TwoSideSection = ({ sectionOne, sectionTwo }) => {
  const matches = useMediaQuery("screen and (max-width: 500px)");
  const offset = 200;
  return (
    <div className="two-section-wrapper">
      <motion.div
        initial={{ x: `-${matches ? 0 : offset}px`, opacity: 0 }}
        whileInView={{ x: "0px", opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7, delay: 0.3 }}
      >
        {sectionOne}
      </motion.div>
      <motion.div
        initial={{ x: `${matches ? 0 : offset}px`, opacity: 0 }}
        whileInView={{ x: "0px", opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7, delay: 0.3 }}
      >
        {sectionTwo}
      </motion.div>
    </div>
  );
};

const features = [
  {
    title: "Your Gateway to Variety",
    subtitle:
      "With multiple categories at your fingertips, the possibilities are endless.",
    icon: MdOutlineCategory,
    image: feature1,
  },
  {
    title: "Studio Showcase: Your Style, Your Way",
    subtitle:
      "Convenience at your finger tipsCraft your unique shopping experience with Tagon's captivating product studio.",
    icon: FaRegCirclePlay,
    image: feature2,
  },
  {
    title: "Play & Prosper",
    subtitle: "Turn fun into funds with Tagon's Play and Earn feature.",
    icon: IoGameControllerOutline,
    image: feature3,
  },
  {
    title: "Stars Speak, We Listen",
    subtitle:
      "Receive daily celestial wisdom tailored just for you – only on Tagon.",
    icon: BsStars,
    image: feature4,
  },
  {
    title: "Refer & Earn",
    subtitle:
      "Spread the joy of shopping – refer friends to Tagon and watch the rewards multiply.",
    icon: HiOutlineCurrencyRupee,
    image: feature5,
  },
];

export function useMediaQuery(query) {
  const getMatches = (query) => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}
